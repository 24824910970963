@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300&family=Open+Sans:wght@300&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Fira Code', monospace;
}
